exports.components = {
  "component---src-layouts-blog-post-js": () => import("./../../../src/layouts/blog-post.js" /* webpackChunkName: "component---src-layouts-blog-post-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aviso-de-privacidad-tsx": () => import("./../../../src/pages/aviso-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-category-tsx": () => import("./../../../src/pages/category.tsx" /* webpackChunkName: "component---src-pages-category-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-colecciones-tsx": () => import("./../../../src/pages/colecciones.tsx" /* webpackChunkName: "component---src-pages-colecciones-tsx" */),
  "component---src-pages-garantia-tsx": () => import("./../../../src/pages/garantia.tsx" /* webpackChunkName: "component---src-pages-garantia-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-mas-vendidos-tsx": () => import("./../../../src/pages/mas-vendidos.tsx" /* webpackChunkName: "component---src-pages-mas-vendidos-tsx" */),
  "component---src-pages-preguntas-frecuentes-tsx": () => import("./../../../src/pages/preguntas-frecuentes.tsx" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-terminos-y-condiciones-tsx": () => import("./../../../src/pages/terminos-y-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

